:root{
  --cal-size: 0px;
}
main{
  min-height: 85vh;

}


#showcasesize{
  font-size: 50rem;
}
@media only screen and (max-width: 1224px){
.homecard{
    margin:auto;
    margin-top: 4vh;
    max-width: 85vw ; 
  }
  .card-div{
    background: url('./media/joe11.jpg') no-repeat center center/cover;
    padding-top: 4vh;
    padding-bottom:4vh;
  }
}

@media only screen and (min-width: 1225px){
  .homecard{
      max-width: 50vw ; 
    }
  }
  .shorten{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .responsive-calendar {
    /* by setting font-size, all the elements will correspond */
    font-size: 9px !important; /* default to 10px */

  }
  
  @media (max-width: 1500px) {
    .responsive-calendar {
      font-size: 9px !important;
    }
  }
  
  @media (max-width: 1200px) {
    .responsive-calendar {
      font-size: 7px !important;
    }
  }
  
  @media (max-width: 768px) {
    .responsive-calendar {
      font-size: 6px !important;
    }
  }
  
  /* Large screens */
  @media (min-width: 2500px) {
    .responsive-calendar {
      font-size: 12px !important;
    }
  }

  
  .responsive-calendar-home {
    /* by setting font-size, all the elements will correspond */
    font-size: var(--cal-size) !important; /* default to 10px */
    margin:auto;

  }
  
 @media (max-width: 588px){
   .calwrap{

   }
 }
